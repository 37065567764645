.pf-v5-c-app-launcher {
  --pf-v5-c-app-launcher__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-app-launcher__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-app-launcher__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-app-launcher__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-app-launcher--m-top__menu--Top: 0;
  --pf-v5-c-app-launcher--m-top__menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-app-launcher__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-app-launcher__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-app-launcher__toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__toggle--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-app-launcher__toggle--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--BackgroundColor: transparent;
  --pf-v5-c-app-launcher__menu-search--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-search--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-search--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-search--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-search--BottomBorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-app-launcher__menu-search--BottomBorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-app-launcher__menu-search--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-item--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-app-launcher__menu-item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-app-launcher__menu-item--Width: 100%;
  --pf-v5-c-app-launcher__menu-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-app-launcher__menu-item--m-link--PaddingRight: 0;
  --pf-v5-c-app-launcher__menu-item--m-link--hover--BackgroundColor: transparent;
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__menu-item--m-action--Width: auto;
  --pf-v5-c-app-launcher__menu-item--m-action--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-app-launcher__menu-item--m-action--hover--BackgroundColor: transparent;
  --pf-v5-c-app-launcher__menu-item--hover__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__menu-item--m-action--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__menu-item--m-action--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--hover--Color: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-app-launcher__menu-item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item-icon--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-app-launcher__menu-item-icon--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-app-launcher__menu-item-external-icon--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-app-launcher__menu-item-external-icon--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-item-external-icon--TranslateY: -0.0625rem;
  --pf-v5-c-app-launcher__menu-item-external-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-app-launcher__group--group--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__group-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__group-title--PaddingRight: var(--pf-v5-c-app-launcher__menu-item--PaddingRight);
  --pf-v5-c-app-launcher__group-title--PaddingBottom: var(--pf-v5-c-app-launcher__menu-item--PaddingBottom);
  --pf-v5-c-app-launcher__group-title--PaddingLeft: var(--pf-v5-c-app-launcher__menu-item--PaddingLeft);
  --pf-v5-c-app-launcher__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-app-launcher__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-app-launcher__group-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-app-launcher--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.pf-v5-c-app-launcher.pf-m-expanded > .pf-v5-c-app-launcher__toggle {
  color: var(--pf-v5-c-app-launcher__toggle--m-expanded--Color);
}
.pf-v5-c-app-launcher .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-app-launcher--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-app-launcher--c-divider--MarginBottom);
}
.pf-v5-c-app-launcher .pf-v5-c-divider:last-child {
  --pf-v5-c-app-launcher--c-divider--MarginBottom: 0;
}

.pf-v5-c-app-launcher__toggle {
  padding-block-start: var(--pf-v5-c-app-launcher__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__toggle--PaddingRight);
  color: var(--pf-v5-c-app-launcher__toggle--Color);
  background-color: var(--pf-v5-c-app-launcher__toggle--BackgroundColor);
  border: none;
}
.pf-v5-c-app-launcher__toggle:hover {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--hover--Color);
}
.pf-v5-c-app-launcher__toggle:active, .pf-v5-c-app-launcher__toggle.pf-m-active {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--active--Color);
}
.pf-v5-c-app-launcher__toggle:focus {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--focus--Color);
}
.pf-v5-c-app-launcher__toggle:disabled {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-app-launcher__menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-app-launcher__menu--Top);
  z-index: var(--pf-v5-c-app-launcher__menu--ZIndex);
  min-width: 100%;
  padding-block-start: var(--pf-v5-c-app-launcher__menu--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__menu--PaddingBottom);
  background-color: var(--pf-v5-c-app-launcher__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-v5-c-app-launcher__menu--BoxShadow);
}
.pf-v5-c-app-launcher__menu.pf-m-align-right {
  inset-inline-end: 0;
}
.pf-v5-c-app-launcher.pf-m-top .pf-v5-c-app-launcher__menu {
  --pf-v5-c-app-launcher__menu--Top: var(--pf-v5-c-app-launcher--m-top__menu--Top);
  transform: translateY(var(--pf-v5-c-app-launcher--m-top__menu--TranslateY));
}
.pf-v5-c-app-launcher__menu.pf-m-static {
  --pf-v5-c-app-launcher--m-top__menu--TranslateY: 0;
  position: static;
  inset-block-start: auto;
  inset-block-end: auto;
  inset-inline-start: auto;
  inset-inline-end: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-v5-c-app-launcher__menu-search {
  padding-block-start: var(--pf-v5-c-app-launcher__menu-search--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__menu-search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__menu-search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__menu-search--PaddingRight);
  margin-block-end: var(--pf-v5-c-app-launcher__menu-search--MarginBottom);
  border-block-end: var(--pf-v5-c-app-launcher__menu-search--BottomBorderWidth) solid var(--pf-v5-c-app-launcher__menu-search--BottomBorderColor);
}

.pf-v5-c-app-launcher__menu-wrapper {
  display: flex;
}
.pf-v5-c-app-launcher__menu-wrapper.pf-m-favorite {
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--Color);
  --pf-v5-c-app-launcher__menu-item--m-action--hover--Color: var(--pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--hover--Color);
}

.pf-v5-c-app-launcher__menu-item {
  display: flex;
  align-items: center;
  width: var(--pf-v5-c-app-launcher__menu-item--Width);
  padding-block-start: var(--pf-v5-c-app-launcher__menu-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__menu-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__menu-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__menu-item--PaddingRight);
  font-weight: var(--pf-v5-c-app-launcher__menu-item--FontWeight);
  color: var(--pf-v5-c-app-launcher__menu-item--Color);
  white-space: nowrap;
  background: none;
  border: 0;
}
.pf-v5-c-app-launcher__menu-item:hover, .pf-v5-c-app-launcher__menu-item:focus {
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--hover__menu-item--m-action--Color);
  text-decoration: none;
}
.pf-v5-c-app-launcher__menu-wrapper:hover,
.pf-v5-c-app-launcher__menu-wrapper:focus-within,
.pf-v5-c-app-launcher__menu-wrapper.pf-m-focus, .pf-v5-c-app-launcher__menu-item:hover, .pf-v5-c-app-launcher__menu-item:focus {
  background-color: var(--pf-v5-c-app-launcher__menu-item--hover--BackgroundColor);
}

.pf-v5-c-app-launcher__menu-item:disabled, .pf-v5-c-app-launcher__menu-item.pf-m-disabled {
  --pf-v5-c-app-launcher__menu-item--Color: var(--pf-v5-c-app-launcher__menu-item--disabled--Color);
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--m-action--disabled--Color);
  pointer-events: none;
}
.pf-v5-c-app-launcher__menu-wrapper:disabled, .pf-v5-c-app-launcher__menu-wrapper.pf-m-disabled, .pf-v5-c-app-launcher__menu-item:disabled, .pf-v5-c-app-launcher__menu-item.pf-m-disabled {
  background-color: transparent;
}

.pf-v5-c-app-launcher__menu-wrapper.pf-m-external:hover .pf-v5-c-app-launcher__menu-item-external-icon, .pf-v5-c-app-launcher__menu-wrapper.pf-m-external:focus .pf-v5-c-app-launcher__menu-item-external-icon, .pf-v5-c-app-launcher__menu-item.pf-m-external:hover .pf-v5-c-app-launcher__menu-item-external-icon, .pf-v5-c-app-launcher__menu-item.pf-m-external:focus .pf-v5-c-app-launcher__menu-item-external-icon {
  opacity: 1;
}

.pf-v5-c-app-launcher__menu-item.pf-m-link {
  --pf-v5-c-app-launcher__menu-item--PaddingRight: var(--pf-v5-c-app-launcher__menu-item--m-link--PaddingRight);
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-c-app-launcher__menu-item--m-link--hover--BackgroundColor);
}
.pf-v5-c-app-launcher__menu-item.pf-m-action {
  --pf-v5-c-app-launcher__menu-item--Color: var(--pf-v5-c-app-launcher__menu-item--m-action--Color);
  --pf-v5-c-app-launcher__menu-item--Width: var(--pf-v5-c-app-launcher__menu-item--m-action--Width);
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-c-app-launcher__menu-item--m-action--hover--BackgroundColor);
  font-size: var(--pf-v5-c-app-launcher__menu-item--m-action--FontSize);
}
.pf-v5-c-app-launcher__menu-item.pf-m-action:hover, .pf-v5-c-app-launcher__menu-item.pf-m-action:focus {
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--m-action--hover--Color);
}

.pf-v5-c-app-launcher__menu-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-app-launcher__menu-item-icon--Width);
  height: var(--pf-v5-c-app-launcher__menu-item-icon--Height);
  margin-inline-end: var(--pf-v5-c-app-launcher__menu-item-icon--MarginRight);
}
.pf-v5-c-app-launcher__menu-item-icon > * {
  max-width: 100%;
  max-height: 100%;
}

.pf-v5-c-app-launcher__menu-item-external-icon {
  padding-inline-start: var(--pf-v5-c-app-launcher__menu-item-external-icon--PaddingLeft);
  margin-inline-start: auto;
  font-size: var(--pf-v5-c-app-launcher__menu-item-external-icon--FontSize);
  color: var(--pf-v5-c-app-launcher__menu-item-external-icon--Color);
  opacity: 0;
  transform: translateY(var(--pf-v5-c-app-launcher__menu-item-external-icon--TranslateY));
}

.pf-v5-c-app-launcher__group + .pf-v5-c-app-launcher__group {
  padding-block-start: var(--pf-v5-c-app-launcher__group--group--PaddingTop);
}

.pf-v5-c-app-launcher__group-title {
  padding-block-start: var(--pf-v5-c-app-launcher__group-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__group-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__group-title--PaddingRight);
  font-size: var(--pf-v5-c-app-launcher__group-title--FontSize);
  font-weight: var(--pf-v5-c-app-launcher__group-title--FontWeight);
  color: var(--pf-v5-c-app-launcher__group-title--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-app-launcher {
  --pf-v5-c-app-launcher__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-app-launcher__menu--Top: 100%;
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-app-launcher__menu-item--disabled--Color: var(--pf-v5-global--palette--black-300);
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--Color: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--hover--Color: var(--pf-v5-global--palette--gold-100);
}