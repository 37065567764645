.pf-v5-c-radio {
  --pf-v5-c-radio--GridGap: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--sm);
  --pf-v5-c-radio__label--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-radio__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-radio__label--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-radio__label--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-radio__label--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-radio__input--TranslateY: calc((var(--pf-v5-c-radio__label--LineHeight) * var(--pf-v5-c-radio__label--FontSize) / 2 ) - 50%);
  --pf-v5-c-radio__input--TranslateY--moz: var(--pf-v5-c-radio__input--TranslateY);
  --pf-v5-c-radio__input--first-child--MarginLeft: 0.0625rem;
  --pf-v5-c-radio__input--last-child--MarginRight: 0.0625rem;
  --pf-v5-c-radio__description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-radio__description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-radio__body--MarginTop: var(--pf-v5-global--spacer--sm);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--pf-v5-c-radio--GridGap);
  align-items: baseline;
}
.pf-v5-c-radio.pf-m-standalone {
  --pf-v5-c-radio--GridGap: 0;
  --pf-v5-c-radio__input--TranslateY: none;
  display: inline-grid;
}

.pf-v5-c-radio__input {
  align-self: start;
  -moz-transform: translateY(var(--pf-v5-c-radio__input--TranslateY--moz));
  transform: translateY(var(--pf-v5-c-radio__input--TranslateY));
}
.pf-v5-c-radio__input:first-child {
  margin-inline-start: var(--pf-v5-c-radio__input--first-child--MarginLeft);
}
.pf-v5-c-radio__input:last-child {
  margin-inline-end: var(--pf-v5-c-radio__input--last-child--MarginRight);
}

.pf-v5-c-radio__label {
  font-size: var(--pf-v5-c-radio__label--FontSize);
  font-weight: var(--pf-v5-c-radio__label--FontWeight);
  line-height: var(--pf-v5-c-radio__label--LineHeight);
  color: var(--pf-v5-c-radio__label--Color);
}

.pf-v5-c-radio__description {
  grid-column: 2;
  font-size: var(--pf-v5-c-radio__description--FontSize);
  color: var(--pf-v5-c-radio__description--Color);
}

.pf-v5-c-radio__body {
  grid-column: 2;
  margin-block-start: var(--pf-v5-c-radio__body--MarginTop);
}

.pf-v5-c-radio__label,
.pf-v5-c-radio__input {
  justify-self: start;
}
label.pf-v5-c-radio, .pf-v5-c-radio__label,
.pf-v5-c-radio__input {
  cursor: pointer;
}

.pf-v5-c-radio__label:disabled, .pf-v5-c-radio__label.pf-m-disabled,
.pf-v5-c-radio__input:disabled,
.pf-v5-c-radio__input.pf-m-disabled {
  --pf-v5-c-radio__label--Color: var(--pf-v5-c-radio__label--disabled--Color);
  cursor: not-allowed;
}