.pf-v5-c-progress {
  --pf-v5-c-progress--GridGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-progress__bar--Height: var(--pf-v5-global--spacer--md);
  --pf-v5-c-progress__bar--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-progress__measure--m-static-width--MinWidth: 4.5ch;
  --pf-v5-c-progress__status--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress__bar--before--Opacity: .2;
  --pf-v5-c-progress__indicator--Height: var(--pf-v5-c-progress__bar--Height);
  --pf-v5-c-progress__indicator--BackgroundColor: var(--pf-v5-c-progress__bar--before--BackgroundColor);
  --pf-v5-c-progress__helper-text--MarginTop: calc(var(--pf-v5-global--spacer--xs) - var(--pf-v5-c-progress--GridGap));
  --pf-v5-c-progress--m-success__bar--BackgroundColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-progress--m-warning__bar--BackgroundColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-progress--m-danger__bar--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress--m-success__status-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-progress--m-warning__status-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-progress--m-danger__status-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress--m-inside__indicator--MinWidth: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-progress--m-success--m-inside__measure--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-progress--m-warning--m-inside__measure--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-progress--m-inside__measure--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-outside__measure--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-sm__bar--Height: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress--m-sm__description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-sm__measure--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-lg__bar--Height: var(--pf-v5-global--spacer--lg);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto auto;
  grid-gap: var(--pf-v5-c-progress--GridGap);
  align-items: end;
}
.pf-v5-c-progress.pf-m-sm {
  --pf-v5-c-progress__bar--Height: var(--pf-v5-c-progress--m-sm__bar--Height);
}
.pf-v5-c-progress.pf-m-sm .pf-v5-c-progress__description {
  font-size: var(--pf-v5-c-progress--m-sm__description--FontSize);
}
.pf-v5-c-progress.pf-m-sm .pf-v5-c-progress__measure {
  font-size: var(--pf-v5-c-progress--m-sm__measure--FontSize);
}
.pf-v5-c-progress.pf-m-lg {
  --pf-v5-c-progress__bar--Height: var(--pf-v5-c-progress--m-lg__bar--Height);
}
.pf-v5-c-progress.pf-m-inside .pf-v5-c-progress__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--pf-v5-c-progress--m-inside__indicator--MinWidth);
}
.pf-v5-c-progress.pf-m-inside .pf-v5-c-progress__measure {
  font-size: var(--pf-v5-c-progress--m-inside__measure--FontSize);
  color: var(--pf-v5-c-progress--m-inside__measure--Color);
  text-align: center;
}
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__description {
  grid-column: 1/3;
}
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__status {
  grid-row: 2/3;
  grid-column: 2/3;
  align-self: center;
}
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__measure {
  display: inline-block;
  font-size: var(--pf-v5-c-progress--m-outside__measure--FontSize);
}
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__measure.pf-m-static-width {
  min-width: var(--pf-v5-c-progress__measure--m-static-width--MinWidth);
  text-align: start;
}
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__bar,
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__indicator {
  grid-column: 1/2;
}
.pf-v5-c-progress.pf-m-singleline {
  grid-template-rows: 1fr;
}
.pf-v5-c-progress.pf-m-singleline .pf-v5-c-progress__description {
  display: none;
}
.pf-v5-c-progress.pf-m-singleline .pf-v5-c-progress__bar {
  grid-row: 1/2;
  grid-column: 1/2;
}
.pf-v5-c-progress.pf-m-singleline .pf-v5-c-progress__status {
  grid-row: 1/2;
  grid-column: 2/3;
}
.pf-v5-c-progress.pf-m-outside, .pf-v5-c-progress.pf-m-singleline {
  grid-template-columns: 1fr fit-content(50%);
}
.pf-v5-c-progress.pf-m-success {
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-c-progress--m-success__bar--BackgroundColor);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-c-progress--m-success__status-icon--Color);
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-c-progress--m-success--m-inside__measure--Color);
}
.pf-v5-c-progress.pf-m-warning {
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-c-progress--m-warning__bar--BackgroundColor);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-c-progress--m-warning__status-icon--Color);
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-c-progress--m-warning--m-inside__measure--Color);
}
.pf-v5-c-progress.pf-m-danger {
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-c-progress--m-danger__bar--BackgroundColor);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-c-progress--m-danger__status-icon--Color);
}

.pf-v5-c-progress__description {
  grid-column: 1/2;
  word-break: break-word;
}
.pf-v5-c-progress__description.pf-m-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-progress__status {
  display: flex;
  grid-row: 1/2;
  grid-column: 2/3;
  gap: var(--pf-v5-c-progress__status--Gap);
  align-items: flex-start;
  justify-content: flex-end;
  text-align: end;
  word-break: break-word;
}

.pf-v5-c-progress__status-icon {
  color: var(--pf-v5-c-progress__status-icon--Color);
}

.pf-v5-c-progress__bar::before,
.pf-v5-c-progress__indicator {
  top: 0;
  left: 0;
}

.pf-v5-c-progress__bar {
  position: relative;
  grid-row: 2/3;
  grid-column: 1/3;
  align-self: center;
  height: var(--pf-v5-c-progress__bar--Height);
  background-color: var(--pf-v5-c-progress__bar--BackgroundColor);
}
.pf-v5-c-progress__bar::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--pf-v5-c-progress__bar--before--BackgroundColor);
  opacity: var(--pf-v5-c-progress__bar--before--Opacity);
}

.pf-v5-c-progress__indicator {
  position: absolute;
  height: var(--pf-v5-c-progress__indicator--Height);
  background-color: var(--pf-v5-c-progress__indicator--BackgroundColor);
}

.pf-v5-c-progress__helper-text {
  grid-row: 3/4;
  grid-column: 1/3;
  margin-block-start: var(--pf-v5-c-progress__helper-text--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-progress {
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-progress--m-success--m-inside__measure--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-progress--m-warning--m-inside__measure--Color: var(--pf-v5-global--palette--black-900);
}