.pf-v5-c-form-control {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-form-control {
  --pf-v5-c-form-control--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form-control--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-form-control--Resize: none;
  --pf-v5-c-form-control--OutlineOffset: -2px;
  --pf-v5-c-form-control--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderBottomWidth: 0;
  --pf-v5-c-form-control--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderBottomColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-form-control--Width: 100%;
  --pf-v5-c-form-control--inset--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-form-control--m-placeholder--child--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--after--BorderColor: transparent;
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor: transparent;
  --pf-v5-c-form-control--m-readonly--m-plain--inset--base: 0;
  --pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset: 0;
  --pf-v5-c-form-control--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-success--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-warning--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-error--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control--m-error--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base) + var(--pf-v5-c-form-control--m-icon--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control--m-icon--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--icon--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--m-icon--icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base) + var(--pf-v5-c-form-control--m-error--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer) + var(--pf-v5-c-form-control--m-icon--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form-control__select--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__select--m-success--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-warning--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-error--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control--textarea--Width: var(--pf-v5-c-form-control--Width);
  --pf-v5-c-form-control--textarea--Height: auto;
  --pf-v5-c-form-control__icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control--m-success__icon--m-status--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-warning__icon--m-status--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-error__icon--m-status--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control__utilities--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__utilities--PaddingTop: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__utilities--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__toggle-icon--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--100);
  color: var(--pf-v5-c-form-control--Color);
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--pf-v5-c-form-control--ColumnGap);
  align-items: start;
  width: var(--pf-v5-c-form-control--Width);
  font-size: var(--pf-v5-c-form-control--FontSize);
  line-height: var(--pf-v5-c-form-control--LineHeight);
  resize: var(--pf-v5-c-form-control--Resize);
  background-color: var(--pf-v5-c-form-control--BackgroundColor);
}
.pf-v5-c-form-control::before, .pf-v5-c-form-control::after {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
}
.pf-v5-c-form-control::before {
  border-style: var(--pf-v5-c-form-control--before--BorderStyle, solid);
  border-block-start-color: var(--pf-v5-c-form-control--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-form-control--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-form-control--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-form-control--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-form-control--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-form-control--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-form-control--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-form-control--before--BorderRightWidth);
}
.pf-v5-c-form-control::after {
  border-block-end: var(--pf-v5-c-form-control--after--BorderBottomWidth) var(--pf-v5-c-form-control--after--BorderStyle, solid) var(--pf-v5-c-form-control--after--BorderBottomColor);
}
.pf-v5-c-form-control > :is(input, select, textarea) {
  grid-row: 1/2;
  grid-column: 1/-1;
  padding-block-start: var(--pf-v5-c-form-control--PaddingTop);
  padding-block-end: var(--pf-v5-c-form-control--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-form-control--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control--PaddingRight);
  color: var(--pf-v5-c-form-control--Color);
  background-color: transparent;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.pf-v5-c-form-control > :is(input, select, textarea):focus {
  outline-offset: var(--pf-v5-c-form-control--OutlineOffset);
}
.pf-v5-c-form-control > ::placeholder {
  color: var(--pf-v5-c-form-control--m-placeholder--Color);
}
.pf-v5-c-form-control > :is(input, select) {
  text-overflow: ellipsis;
}
.pf-v5-c-form-control.pf-m-readonly {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--BackgroundColor);
}
.pf-v5-c-form-control.pf-m-readonly:not(.pf-m-success, .pf-m-warning, .pf-m-error) {
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor);
}
.pf-v5-c-form-control.pf-m-readonly.pf-m-plain {
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor);
  --pf-v5-c-form-control--inset--base: var(--pf-v5-c-form-control--m-readonly--m-plain--inset--base);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  --pf-v5-c-form-control--OutlineOffset: var(--pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset);
}
.pf-v5-c-form-control:hover {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--hover--after--BorderBottomColor);
}
.pf-v5-c-form-control:focus-within {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--focus--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--focus--after--BorderBottomWidth);
}
.pf-v5-c-form-control.pf-m-expanded {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomWidth);
}
.pf-v5-c-form-control.pf-m-disabled {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-disabled--BackgroundColor);
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-c-form-control--m-disabled__toggle-icon--Color);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  cursor: not-allowed;
}
.pf-v5-c-form-control.pf-m-error {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-error--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-error--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-error__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-error--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-error--after--BorderBottomWidth);
}
.pf-v5-c-form-control.pf-m-error.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}
.pf-v5-c-form-control.pf-m-success {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-success--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-success--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-success__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-success--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-success--after--BorderBottomWidth);
}
.pf-v5-c-form-control.pf-m-success.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}
.pf-v5-c-form-control.pf-m-warning {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-warning--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-warning--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-warning__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-warning--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-warning--after--BorderBottomWidth);
}
.pf-v5-c-form-control.pf-m-warning.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}
.pf-v5-c-form-control.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--PaddingRight);
}
.pf-v5-c-form-control > select {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control__select--PaddingRight);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control__select--PaddingLeft);
}
@-moz-document url-prefix() {
  .pf-v5-c-form-control > select {
    --pf-v5-c-form-control--PaddingRight: calc(var(--pf-v5-c-form-control__select--PaddingRight) - 1px);
    --pf-v5-c-form-control--PaddingLeft: calc(var(--pf-v5-c-form-control__select--PaddingLeft) - 4px);
  }
}
.pf-v5-c-form-control.pf-m-placeholder > select {
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-placeholder--Color);
}
.pf-v5-c-form-control.pf-m-placeholder > select * {
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-placeholder--child--Color);
}
.pf-v5-c-form-control.pf-m-placeholder > select *:disabled {
  color: revert;
}
.pf-v5-c-form-control > textarea {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  resize: none;
}
.pf-v5-c-form-control.pf-m-resize-vertical {
  --pf-v5-c-form-control--Resize: vertical;
  overflow: auto;
}
.pf-v5-c-form-control.pf-m-resize-horizontal {
  --pf-v5-c-form-control--Resize: horizontal;
  overflow: auto;
}
.pf-v5-c-form-control.pf-m-resize-both {
  --pf-v5-c-form-control--Resize: both;
  overflow: auto;
}

.pf-v5-c-form-control__icon {
  padding-block-start: var(--pf-v5-c-form-control__icon--PaddingTop);
  color: var(--pf-v5-c-form-control__icon--Color);
}
.pf-v5-c-form-control__icon.pf-m-status {
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-c-form-control__icon--m-status--Color);
}

.pf-v5-c-form-control__toggle-icon {
  grid-row: 1/2;
  grid-column: 3;
  padding-block-start: var(--pf-v5-c-form-control__toggle-icon--PaddingTop);
  padding-inline-start: var(--pf-v5-c-form-control__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control__toggle-icon--PaddingRight);
  color: var(--pf-v5-c-form-control__toggle-icon--Color);
  pointer-events: none;
}

.pf-v5-c-form-control__utilities {
  display: flex;
  flex-wrap: nowrap;
  grid-row: 1/2;
  grid-column: 2;
  gap: var(--pf-v5-c-form-control__utilities--Gap);
  padding-inline-end: var(--pf-v5-c-form-control__utilities--PaddingRight);
  pointer-events: none;
}

:where(.pf-v5-theme-dark) .pf-v5-c-form-control {
  --pf-v5-c-form-control--before--BorderTopColor: transparent;
  --pf-v5-c-form-control--before--BorderRightColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: transparent;
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor:var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--300);
  color: var(--pf-v5-global--Color--100);
}
:where(.pf-v5-theme-dark) .pf-v5-c-form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
}