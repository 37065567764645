.chr-c-feedback-modal {
  margin: 0;
  padding: 0;
}
.chr-c-feedback-modal .pf-c-content {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}
.chr-c-feedback-modal .pf-c-modal-box__body {
  padding: 0;
  margin: 0;
}
.chr-c-feedback-modal .pf-c-modal-box > .pf-c-button + * {
  margin-right: 0;
}
.chr-c-feedback-modal .pf-c-modal-box__body:last-child {
  padding-bottom: 0;
}

.chr-c-feedback-cards {
  margin: var(--pf-v5-global--spacer--sm);
}

.chr-c-feedback-card-title {
  color: var(--pf-v5-global--link--Color);
}

.chr-c-feedback {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.chr-c-feedback-content {
  display: flex;
  flex-direction: column;
  padding-right: var(--pf-v5-global--spacer--lg);
  padding-top: var(--pf-v5-global--spacer--lg);
  margin: var(--pf-v5-global--spacer--md);
}

.chr-c-feedback-button {
  margin: var(--pf-v5-global--spacer--sm);
  text-align: left;
  position: absolute;
  bottom: var(--pf-v5-global--spacer--md);
}

.chr-c-feedback-footer-button {
  margin: var(--pf-v5-global--spacer--md);
}

.chr-c-feedback-buttons {
  margin: var(--pf-v5-global--spacer--sm);
  position: absolute;
  bottom: var(--pf-v5-global--spacer--md);
}

.chr-c-feedback-success-content {
  margin-top: 150px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.chr-c-feedback-email {
  margin-top: var(--pf-v5-global--spacer--lg);
  overflow: hidden;
}

.chr-c-feedback-image {
  background-color: var(--pf-v5-global--palette--black-150);
}

.chr-c-feedback-panel {
  margin-bottom: var(--pf-v5-global--spacer--lg);
}
.chr-c-feedback-panel__body {
  padding-bottom: 4px;
  padding-top: 4px;
  border-bottom: 1px solid var(--pf-v5-global--palette--black-500);
}

.chr-c-feedback-text-area {
  resize: none;
}