.pf-v5-c-card {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-card--first-child--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--child--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--child--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--c-divider--child--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card__title--not--last-child--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-card__title-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-card__title-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-card__title-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-card__body--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-card__footer--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-card__actions--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card__actions--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-card__actions--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__actions--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header-toggle--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header-toggle--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-card__header-toggle--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header-toggle--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-card__header-toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-card--m-expanded__header-toggle-icon--Rotate: 90deg;
  --pf-v5-c-card--m-selectable--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-card--m-selectable--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-card--m-selectable--m-selected--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable--hover--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-card--m-selectable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable--focus--BackgroundColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-card--m-selectable--focus--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable--m-selected--focus--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable--m-disabled--BackgroundColor: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-card--m-selectable--m-disabled--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-card--m-selectable--m-disabled--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-card--m-selectable--m-clickable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-card--m-selectable--m-clickable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-card--m-selectable--m-clickable--m-selected--BorderColor: transparent;
  --pf-v5-c-card--m-selectable--m-clickable--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-card--m-selectable--m-clickable--m-current--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-hoverable-raised--hover--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--before--Right: 0;
  --pf-v5-c-card--m-selectable-raised--before--Bottom: 0;
  --pf-v5-c-card--m-selectable-raised--before--Left: 0;
  --pf-v5-c-card--m-flat--m-selectable-raised--before--Right: calc(-1 * var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-flat--m-selectable-raised--before--Bottom: calc(-1 * var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-flat--m-selectable-raised--before--Left: calc(-1 * var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-selectable-raised--before--Height: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: transparent;
  --pf-v5-c-card--m-selectable-raised--before--Transition: none;
  --pf-v5-c-card--m-selectable-raised--before--ScaleY: 1;
  --pf-v5-c-card--m-selectable-raised--before--TranslateY: 0;
  --pf-v5-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable-raised--hover--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable-raised--focus--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--active--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable-raised--active--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base: -0.5rem;
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base);
  --pf-v5-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-v5-c-card--m-rounded--BorderRadius));
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--Transition: transform .25s linear, box-shadow .25s linear;
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--Transition: transform .25s linear;
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--TranslateY: calc(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY) * -1);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--ScaleY: 2;
  --pf-v5-c-card--m-non-selectable-raised--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-200);
  --pf-v5-c-card--m-non-selectable-raised--before--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-card--m-non-selectable-raised--before--ScaleY: 2;
  --pf-v5-c-card--m-flat--m-non-selectable-raised--before--BorderColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-card--m-compact__body--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-card--m-compact__footer--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-card--m-compact--first-child--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--child--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--c-divider--child--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact__title--not--last-child--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-card--m-display-lg__title-text--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-card--m-display-lg--first-child--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--child--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--child--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--child--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--c-divider--child--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg__title--not--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--m-flat--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-card--m-flat--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-card--m-rounded--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-card--m-full-height--Height: 100%;
  --pf-v5-c-card--m-plain--BoxShadow: none;
  --pf-v5-c-card--m-plain--BackgroundColor: transparent;
  --pf-v5-c-card__header--m-toggle-right--toggle--MarginRight: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header--m-toggle-right--toggle--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-card__input--focus--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-card__input--focus--BorderColor: var(--pf-v5-global--primary-color--100);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--pf-v5-c-card--BackgroundColor);
  box-shadow: var(--pf-v5-c-card--BoxShadow);
}
.pf-v5-c-card.pf-m-selectable .pf-v5-c-card__selectable-actions :is(.pf-v5-c-check, .pf-v5-c-radio) {
  gap: 0;
}
.pf-v5-c-card.pf-m-selectable, .pf-v5-c-card.pf-m-clickable {
  isolation: isolate;
  box-shadow: none;
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__label {
  position: unset;
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__label::before,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__label::before {
  position: absolute;
  inset: 0;
  cursor: pointer;
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:focus-visible) ~ .pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:focus-visible) ~ .pf-v5-c-radio__label {
  --pf-v5-c-card--BackgroundColor: unset;
  --pf-v5-c-card--BorderColor: unset;
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:checked) ~ .pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:checked) ~ .pf-v5-c-radio__label, .pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-selected {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-selected--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-selected--BorderColor);
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BorderColor);
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current.pf-m-selected, .pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:checked) ~ .pf-v5-c-check__label, .pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:checked) ~ .pf-v5-c-radio__label {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BorderColor);
}
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:disabled) ~ .pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:disabled) ~ .pf-v5-c-radio__label, .pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-disabled {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-disabled--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-disabled--BorderColor);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable--m-disabled--BoxShadow);
}
.pf-v5-c-card.pf-m-hoverable-raised::before, .pf-v5-c-card.pf-m-selectable-raised::before, .pf-v5-c-card.pf-m-non-selectable-raised::before {
  position: absolute;
  inset-block-end: var(--pf-v5-c-card--m-selectable-raised--before--Bottom);
  inset-inline-start: var(--pf-v5-c-card--m-selectable-raised--before--Left);
  inset-inline-end: var(--pf-v5-c-card--m-selectable-raised--before--Right);
  height: var(--pf-v5-c-card--m-selectable-raised--before--Height);
  content: "";
  background-color: var(--pf-v5-c-card--m-selectable-raised--before--BackgroundColor);
  transition: var(--pf-v5-c-card--m-selectable-raised--before--Transition);
  transform: translateY(var(--pf-v5-c-card--m-selectable-raised--before--TranslateY)) scaleY(var(--pf-v5-c-card--m-selectable-raised--before--ScaleY));
  transform-origin: center bottom;
}
.pf-v5-c-card.pf-m-hoverable-raised:hover {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-hoverable-raised--hover--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-hoverable-raised--hover--before--BackgroundColor);
}
.pf-v5-c-card.pf-m-selectable-raised {
  cursor: pointer;
}
.pf-v5-c-card.pf-m-selectable-raised:hover {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable-raised--hover--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--hover--before--BackgroundColor);
}
.pf-v5-c-card.pf-m-selectable-raised:focus {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable-raised--focus--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--focus--before--BackgroundColor);
}
.pf-v5-c-card.pf-m-selectable-raised:active {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable-raised--active--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--active--before--BackgroundColor);
}
.pf-v5-c-card.pf-m-selectable-raised.pf-m-selected-raised {
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor);
  --pf-v5-c-card--m-selectable-raised--before--Transition: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--Transition);
  --pf-v5-c-card--m-selectable-raised--before--TranslateY: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--TranslateY);
  --pf-v5-c-card--m-selectable-raised--before--ScaleY: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--ScaleY);
  z-index: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--ZIndex);
  box-shadow: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--BoxShadow);
  transition: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--Transition);
  transform: translateY(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY));
}
.pf-v5-c-card.pf-m-non-selectable-raised {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-non-selectable-raised--BackgroundColor);
  --pf-v5-c-card--BoxShadow: none;
  --pf-v5-c-card--m-flat--BorderColor: var(--pf-v5-c-card--m-flat--m-non-selectable-raised--before--BorderColor);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-non-selectable-raised--before--BackgroundColor);
  --pf-v5-c-card--m-selectable-raised--before--ScaleY: var(--pf-v5-c-card--m-non-selectable-raised--before--ScaleY);
}
.pf-v5-c-card.pf-m-compact {
  --pf-v5-c-card__body--FontSize: var(--pf-v5-c-card--m-compact__body--FontSize);
  --pf-v5-c-card__footer--FontSize: var(--pf-v5-c-card--m-compact__footer--FontSize);
  --pf-v5-c-card--first-child--PaddingTop: var(--pf-v5-c-card--m-compact--first-child--PaddingTop);
  --pf-v5-c-card--child--PaddingRight: var(--pf-v5-c-card--m-compact--child--PaddingRight);
  --pf-v5-c-card--child--PaddingBottom: var(--pf-v5-c-card--m-compact--child--PaddingBottom);
  --pf-v5-c-card--child--PaddingLeft: var(--pf-v5-c-card--m-compact--child--PaddingLeft);
  --pf-v5-c-card--c-divider--child--PaddingTop: var(--pf-v5-c-card--m-compact--c-divider--child--PaddingTop);
  --pf-v5-c-card__title--not--last-child--PaddingBottom: var(--pf-v5-c-card--m-compact__title--not--last-child--PaddingBottom);
}
.pf-v5-c-card.pf-m-display-lg {
  --pf-v5-c-card__title-text--FontSize: var(--pf-v5-c-card--m-display-lg__title-text--FontSize);
  --pf-v5-c-card--first-child--PaddingTop: var(--pf-v5-c-card--m-display-lg--first-child--PaddingTop);
  --pf-v5-c-card--child--PaddingRight: var(--pf-v5-c-card--m-display-lg--child--PaddingRight);
  --pf-v5-c-card--child--PaddingBottom: var(--pf-v5-c-card--m-display-lg--child--PaddingBottom);
  --pf-v5-c-card--child--PaddingLeft: var(--pf-v5-c-card--m-display-lg--child--PaddingLeft);
  --pf-v5-c-card--c-divider--child--PaddingTop: var(--pf-v5-c-card--m-display-lg--c-divider--child--PaddingTop);
  --pf-v5-c-card__title--not--last-child--PaddingBottom: var(--pf-v5-c-card--m-display-lg__title--not--last-child--PaddingBottom);
}
.pf-v5-c-card.pf-m-flat {
  --pf-v5-c-card--BoxShadow: none;
  --pf-v5-c-card--m-selectable-raised--before--Right: var(--pf-v5-c-card--m-flat--m-selectable-raised--before--Right);
  --pf-v5-c-card--m-selectable-raised--before--Bottom: var(--pf-v5-c-card--m-flat--m-selectable-raised--before--Bottom);
  --pf-v5-c-card--m-selectable-raised--before--Left: var(--pf-v5-c-card--m-flat--m-selectable-raised--before--Left);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-v5-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY);
  border: var(--pf-v5-c-card--m-flat--BorderWidth) solid var(--pf-v5-c-card--m-flat--BorderColor);
}
.pf-v5-c-card.pf-m-plain {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-plain--BoxShadow);
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-plain--BackgroundColor);
}
.pf-v5-c-card.pf-m-rounded {
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-v5-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY);
  border-radius: var(--pf-v5-c-card--m-rounded--BorderRadius);
}
.pf-v5-c-card.pf-m-expanded .pf-v5-c-card__header-toggle-icon {
  transform: rotate(var(--pf-v5-c-card--m-expanded__header-toggle-icon--Rotate));
}
.pf-v5-c-card.pf-m-full-height {
  height: var(--pf-v5-c-card--m-full-height--Height);
}
.pf-v5-c-card > .pf-v5-c-divider + .pf-v5-c-card__header, .pf-v5-c-card > .pf-v5-c-divider + .pf-v5-c-card__title, .pf-v5-c-card > .pf-v5-c-divider + .pf-v5-c-card__body, .pf-v5-c-card > .pf-v5-c-divider + .pf-v5-c-card__footer {
  padding-block-start: var(--pf-v5-c-card--c-divider--child--PaddingTop);
}

.pf-v5-c-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pf-v5-c-card__header .pf-v5-c-card__title {
  --pf-v5-c-card--first-child--PaddingTop: 0;
  --pf-v5-c-card__title--not--last-child--PaddingBottom: 0;
  padding: 0;
}
.pf-v5-c-card__header.pf-m-toggle-right {
  --pf-v5-c-card__header-toggle--MarginRight: var(--pf-v5-c-card__header--m-toggle-right--toggle--MarginRight);
  --pf-v5-c-card__header-toggle--MarginLeft: var(--pf-v5-c-card__header--m-toggle-right--toggle--MarginLeft);
}
.pf-v5-c-card__header.pf-m-toggle-right .pf-v5-c-card__header-toggle {
  order: 2;
}

.pf-v5-c-card__header-main {
  flex: 1;
}

.pf-v5-c-card__header-toggle {
  align-self: flex-start;
  margin-block-start: var(--pf-v5-c-card__header-toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-card__header-toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-card__header-toggle--MarginLeft);
  margin-inline-end: var(--pf-v5-c-card__header-toggle--MarginRight);
}

.pf-v5-c-card__header-toggle-icon {
  display: inline-block;
  transition: var(--pf-v5-c-card__header-toggle-icon--Transition);
}
:where(.pf-v5-m-dir-rtl, [dir=rtl]) .pf-v5-c-card__header-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-card__title-text {
  font-family: var(--pf-v5-c-card__title-text--FontFamily);
  font-size: var(--pf-v5-c-card__title-text--FontSize);
  font-weight: var(--pf-v5-c-card__title-text--FontWeight);
  line-height: var(--pf-v5-c-card__title-text--LineHeight);
}

.pf-v5-c-card__actions {
  display: flex;
  gap: var(--pf-v5-c-card__actions--Gap);
  align-items: center;
  align-self: flex-start;
  order: 1;
  padding-inline-start: var(--pf-v5-c-card__actions--PaddingLeft);
  margin-block-start: var(--pf-v5-c-card__actions--MarginTop);
  margin-block-end: var(--pf-v5-c-card__actions--MarginBottom);
  margin-inline-start: auto;
}
.pf-v5-c-card__actions + .pf-v5-c-card__title,
.pf-v5-c-card__actions + .pf-v5-c-card__body,
.pf-v5-c-card__actions + .pf-v5-c-card__footer {
  padding: 0;
}
.pf-v5-c-card__actions.pf-m-no-offset {
  --pf-v5-c-card__actions--MarginTop: 0;
  --pf-v5-c-card__actions--MarginBottom: 0;
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__label {
  position: absolute;
  inset: 0;
  justify-self: auto;
  cursor: pointer;
}
.pf-v5-c-card__selectable-actions .pf-v5-c-check__label::before,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__label::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background-color: var(--pf-v5-c-card--BackgroundColor, transparent);
  border: var(--pf-v5-c-card--m-selectable--BorderWidth) solid var(--pf-v5-c-card--BorderColor, transparent);
  box-shadow: var(--pf-v5-c-card--BoxShadow, none);
}
.pf-v5-c-card__selectable-actions .pf-v5-c-check__label:hover,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__label:hover {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--hover--BackgroundColor);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable--hover--BoxShadow);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:checked) ~ .pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:checked) ~ .pf-v5-c-radio__label,
.pf-v5-c-card.pf-m-selected {
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-selected--BorderColor);
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-selected--BackgroundColor);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:focus-visible) ~ .pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:focus-visible) ~ .pf-v5-c-radio__label {
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--focus--BorderColor);
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--focus--BackgroundColor);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:focus-visible):where(:checked) ~ .pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:focus-visible):where(:checked) ~ .pf-v5-c-radio__label {
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-selected--focus--BorderColor);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:disabled) ~ .pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:disabled) ~ .pf-v5-c-radio__label,
.pf-v5-c-card.pf-m-disabled {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-disabled--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-disabled--BorderColor);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable--m-disabled--BoxShadow);
}

.pf-v5-c-card__header,
.pf-v5-c-card__title,
.pf-v5-c-card__body,
.pf-v5-c-card__footer {
  padding-block-end: var(--pf-v5-c-card--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-card--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-card--child--PaddingRight);
}
.pf-v5-c-card__header:first-child,
.pf-v5-c-card__title:first-child,
.pf-v5-c-card__body:first-child,
.pf-v5-c-card__footer:first-child {
  padding-block-start: var(--pf-v5-c-card--first-child--PaddingTop);
}

.pf-v5-c-card__header:not(:last-child),
.pf-v5-c-card__title:not(:last-child) {
  padding-block-end: var(--pf-v5-c-card__title--not--last-child--PaddingBottom);
}

.pf-v5-c-card__expandable-content {
  --pf-v5-c-card--first-child--PaddingTop: 0;
}

.pf-v5-c-card__body:not(.pf-m-no-fill) {
  flex: 1 1 auto;
}

.pf-v5-c-card__body {
  font-size: var(--pf-v5-c-card__body--FontSize);
}

.pf-v5-c-card__footer {
  font-size: var(--pf-v5-c-card__footer--FontSize);
}

.pf-v5-c-card__sr-input:focus + .pf-v5-c-card::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-card__input--focus--BorderWidth) solid var(--pf-v5-c-card__input--focus--BorderColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-card,
:where(.pf-v5-theme-dark) .pf-v5-c-card.pf-m-non-selectable-raised {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable--m-disabled--BackgroundColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-card--m-selectable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-card--m-selectable--focus--BackgroundColor: var(--pf-v5-global--palette--blue-600);
  --pf-v5-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--active--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
}