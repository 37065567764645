.pf-v5-c-data-list {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}
.pf-m-hidden.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}
@media screen and (min-width: 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 768px) {
  .pf-m-hidden-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}
@media screen and (min-width: 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }
  .pf-m-visible-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 768px) and (min-width: 1200px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 768px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 0) {
  .pf-v5-c-data-list.pf-m-grid-none {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 0) and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-none {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 0) {
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list.pf-m-grid-sm {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 576px) and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-sm {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 576px) {
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list.pf-m-grid-md {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 768px) and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-md {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 768px) {
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-data-list.pf-m-grid-lg {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 992px) and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-lg {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 992px) {
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 1200px) and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-data-list.pf-m-grid-2xl {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}
@media screen and (min-width: 1450px) and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-2xl {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}
@media screen and (min-width: 1450px) {
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

.pf-v5-c-data-list {
  --pf-v5-c-data-list--FontSize: 1em;
  --pf-v5-c-data-list--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-data-list--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-data-list--BorderTopWidth: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--sm--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-data-list--sm--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-data-list__item--m-selected--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-data-list__item--m-expanded--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-data-list__item--m-selected--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-data-list__item--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--m-clickable--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-data-list__item--m-clickable--hover--ZIndex: calc(var(--pf-v5-c-data-list__item--m-selected--ZIndex) + 1);
  --pf-v5-c-data-list__item--m-clickable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--m-clickable--focus--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--m-clickable--active--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--BorderBottomColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-data-list__item--BorderBottomWidth: 0.5rem;
  --pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopColor: var(--pf-v5-c-data-list__item--BorderBottomColor);
  --pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopWidth: var(--pf-v5-c-data-list__item--BorderBottomWidth);
  --pf-v5-c-data-list__item--sm--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-data-list__item--sm--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list__item--before--BackgroundColor: transparent;
  --pf-v5-c-data-list__item--before--Width: calc(2 * var(--pf-v5-global--BorderWidth--lg));
  --pf-v5-c-data-list__item--before--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-data-list__item--before--Top: 0;
  --pf-v5-c-data-list__item--before--sm--Top: calc(var(--pf-v5-c-data-list__item--BorderBottomWidth) * -1);
  --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-row--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-row--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-content--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--MarginRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-data-list__cell--md--PaddingBottom: 0;
  --pf-v5-c-data-list__cell--m-icon--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__cell--cell--PaddingTop: 0;
  --pf-v5-c-data-list__cell--cell--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--m-icon--cell--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list--cell--MinWidth: initial;
  --pf-v5-c-data-list--cell--Overflow: visible;
  --pf-v5-c-data-list--cell--TextOverflow: clip;
  --pf-v5-c-data-list--cell--WhiteSpace: normal;
  --pf-v5-c-data-list--cell--WordBreak: normal;
  --pf-v5-c-data-list--cell--m-truncate--MinWidth: 5ch;
  --pf-v5-c-data-list__toggle--MarginLeft: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-data-list__toggle--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__toggle--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__toggle-icon--Height: calc(var(--pf-v5-c-data-list--FontSize) * var(--pf-v5-c-data-list--LineHeight));
  --pf-v5-c-data-list__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-data-list__toggle-icon--Rotate: 0;
  --pf-v5-c-data-list__item--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-data-list__item-draggable-button--BackgroundColor: transparent;
  --pf-v5-c-data-list__item-draggable-button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-draggable-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-draggable-button--MarginTop: calc(var(--pf-v5-global--spacer--lg) * -1);
  --pf-v5-c-data-list__item-draggable-button--MarginBottom: calc(var(--pf-v5-global--spacer--lg) * -1);
  --pf-v5-c-data-list__item-draggable-button--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-draggable-button--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-draggable-button--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-data-list__item-draggable-button--m-disabled__draggable-icon--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-data-list__item-draggable-button--hover__draggable-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-data-list__item-draggable-button--focus__draggable-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-data-list__item--m-ghost-row--after--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-data-list__item--m-ghost-row--after--Opacity: .6;
  --pf-v5-c-data-list__item-control--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-control--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-control--md--MarginRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-data-list__item-control--not-last-child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__check--Height: calc(var(--pf-v5-c-data-list--FontSize) * var(--pf-v5-c-data-list--LineHeight));
  --pf-v5-c-data-list__check--MarginTop: -0.0625rem;
  --pf-v5-c-data-list__item-action--Display: flex;
  --pf-v5-c-data-list__item-action--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-action--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-action--md--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-data-list__item-action--not-last-child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-action__action--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__action--MarginTop: var(--pf-v5-c-data-list__item-action__action--MarginTop);
  --pf-v5-c-data-list__item-action__action--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__expandable-content--MarginRight: calc(var(--pf-v5-c-data-list__expandable-content-body--PaddingRight) * -1);
  --pf-v5-c-data-list__expandable-content--MarginLeft: calc(var(--pf-v5-c-data-list__expandable-content-body--PaddingLeft) * -1);
  --pf-v5-c-data-list__expandable-content--MaxHeight: 37.5rem;
  --pf-v5-c-data-list__expandable-content--before--Top: calc(var(--pf-v5-c-data-list__item--BorderBottomWidth) * -1);
  --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__expandable-content-body--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__expandable-content-body--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list--m-compact--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-data-list--m-compact--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-data-list--m-compact__check--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-data-list--m-compact__cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--md--PaddingBottom: 0;
  --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: 0;
  --pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--cell--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list--m-compact__item-control--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-control--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-control--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list--m-compact__item-action--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-action--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-action--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list--m-compact__item-action__action--MarginTop: calc(var(--pf-v5-c-data-list--m-compact__item-action--PaddingTop) * -1);
  --pf-v5-c-data-list--m-compact__item-action__action--MarginBottom: calc(var(--pf-v5-c-data-list--m-compact__item-action--PaddingBottom) * -1);
  --pf-v5-c-data-list--m-compact__action--MarginTop: var(--pf-v5-c-data-list--m-compact__item-action__action--MarginTop);
  --pf-v5-c-data-list--m-compact__item-content--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-draggable-button--MarginTop: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-data-list--m-compact__item-draggable-button--MarginBottom: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-data-list--m-compact__item-draggable-button--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-draggable-button--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--m-icon--cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  color: var(--pf-v5-global--Color--100);
  font-size: var(--pf-v5-c-data-list--FontSize);
  line-height: var(--pf-v5-c-data-list--LineHeight);
  overflow-wrap: break-word;
  border-block-start: var(--pf-v5-c-data-list--BorderTopWidth) solid var(--pf-v5-c-data-list--BorderTopColor);
}
@media screen and (min-width: 576px) {
  .pf-v5-c-data-list {
    --pf-v5-c-data-list--BorderTopColor: var(--pf-v5-c-data-list--sm--BorderTopColor);
    --pf-v5-c-data-list--BorderTopWidth: var(--pf-v5-c-data-list--sm--BorderTopWidth);
  }
}
@media screen and (min-width: 576px) {
  .pf-v5-c-data-list {
    --pf-v5-c-data-list__item--BorderBottomWidth: var(--pf-v5-c-data-list__item--sm--BorderBottomWidth);
    --pf-v5-c-data-list__item--BorderBottomColor: var(--pf-v5-c-data-list__item--sm--BorderBottomColor);
  }
}
@media (min-width: 576px) {
  .pf-v5-c-data-list {
    --pf-v5-c-data-list__item--before--Top: var(--pf-v5-c-data-list__item--before--sm--Top);
  }
}
.pf-v5-c-data-list.pf-m-compact {
  --pf-v5-c-data-list__check--FontSize: var(--pf-v5-c-data-list--m-compact__check--FontSize);
  --pf-v5-c-data-list__action--MarginTop: var(--pf-v5-c-data-list--m-compact__action--MarginTop);
  --pf-v5-c-data-list--FontSize: var(--pf-v5-c-data-list--m-compact--FontSize);
  --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list--m-compact__item-action--MarginLeft);
  --pf-v5-c-data-list__item-action--PaddingTop: var(--pf-v5-c-data-list--m-compact__item-action--PaddingTop);
  --pf-v5-c-data-list__item-action--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-action--PaddingBottom);
  --pf-v5-c-data-list__item-action__action--MarginTop: var(--pf-v5-c-data-list--m-compact__item-action__action--MarginTop);
  --pf-v5-c-data-list__item-action__action--MarginBottom: var(--pf-v5-c-data-list--m-compact__item-action__action--MarginBottom);
  --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list--m-compact__item-control--MarginRight);
  --pf-v5-c-data-list__item-control--PaddingTop: var(--pf-v5-c-data-list--m-compact__item-control--PaddingTop);
  --pf-v5-c-data-list__item-control--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-control--PaddingBottom);
  --pf-v5-c-data-list__item-content--md--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-content--PaddingBottom);
  --pf-v5-c-data-list__item-draggable-button--MarginTop: var(--pf-v5-c-data-list--m-compact__item-draggable-button--MarginTop);
  --pf-v5-c-data-list__item-draggable-button--MarginBottom: var(--pf-v5-c-data-list--m-compact__item-draggable-button--MarginBottom);
  --pf-v5-c-data-list__item-draggable-button--PaddingTop: var(--pf-v5-c-data-list--m-compact__item-draggable-button--PaddingTop);
  --pf-v5-c-data-list__item-draggable-button--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-draggable-button--PaddingBottom);
  --pf-v5-c-data-list__cell--m-icon--cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell--m-icon--cell--PaddingTop);
  font-size: var(--pf-v5-c-data-list--m-compact--FontSize);
}
.pf-v5-c-data-list.pf-m-compact .pf-v5-c-data-list__cell {
  --pf-v5-c-data-list__cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell--PaddingTop);
  --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--PaddingBottom);
  --pf-v5-c-data-list__cell--MarginRight: var(--pf-v5-c-data-list--m-compact__cell--cell--MarginRight);
  --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--PaddingTop);
}
.pf-v5-c-data-list.pf-m-compact .pf-v5-c-data-list__check {
  font-size: var(--pf-v5-c-data-list--m-compact__check--FontSize);
}
.pf-v5-c-data-list.pf-m-drag-over {
  overflow-anchor: none;
}

.pf-v5-c-data-list.pf-m-truncate,
.pf-v5-c-data-list__item-row.pf-m-truncate,
.pf-v5-c-data-list__cell.pf-m-truncate,
.pf-v5-c-data-list__text.pf-m-truncate {
  --pf-v5-c-data-list--cell--MinWidth: var(--pf-v5-c-data-list--cell--m-truncate--MinWidth);
  --pf-v5-c-data-list--cell--Overflow: hidden;
  --pf-v5-c-data-list--cell--TextOverflow: ellipsis;
  --pf-v5-c-data-list--cell--WhiteSpace: nowrap;
}
.pf-v5-c-data-list.pf-m-break-word,
.pf-v5-c-data-list__item-row.pf-m-break-word,
.pf-v5-c-data-list__cell.pf-m-break-word,
.pf-v5-c-data-list__text.pf-m-break-word {
  --pf-v5-c-data-list--cell--WordBreak: break-word;
}
.pf-v5-c-data-list.pf-m-nowrap,
.pf-v5-c-data-list__item-row.pf-m-nowrap,
.pf-v5-c-data-list__cell.pf-m-nowrap,
.pf-v5-c-data-list__text.pf-m-nowrap {
  --pf-v5-c-data-list--cell--WhiteSpace: nowrap;
}

.pf-v5-c-data-list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--pf-v5-c-data-list__item--BackgroundColor);
  border-block-end: var(--pf-v5-c-data-list__item--BorderBottomWidth) solid var(--pf-v5-c-data-list__item--BorderBottomColor);
}
.pf-v5-c-data-list__item::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-data-list__item--before--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-data-list__item--before--Width);
  content: "";
  background-color: var(--pf-v5-c-data-list__item--before--BackgroundColor);
  transition: var(--pf-v5-c-data-list__item--before--Transition);
}
.pf-v5-c-data-list__item.pf-m-clickable {
  cursor: pointer;
  outline-offset: var(--pf-v5-c-data-list__item--m-clickable--OutlineOffset);
}
.pf-v5-c-data-list__item.pf-m-clickable:hover, .pf-v5-c-data-list__item.pf-m-clickable:focus {
  position: relative;
  z-index: var(--pf-v5-c-data-list__item--m-clickable--hover--ZIndex);
}
.pf-v5-c-data-list__item.pf-m-clickable:hover:not(.pf-m-selected):not(:last-child), .pf-v5-c-data-list__item.pf-m-clickable:focus:not(.pf-m-selected):not(:last-child) {
  --pf-v5-c-data-list__item--BorderBottomWidth: 0;
}
.pf-v5-c-data-list__item.pf-m-clickable:hover:not(.pf-m-selected):not(:last-child) + .pf-v5-c-data-list__item, .pf-v5-c-data-list__item.pf-m-clickable:focus:not(.pf-m-selected):not(:last-child) + .pf-v5-c-data-list__item {
  border-block-start: var(--pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopWidth) solid var(--pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopColor);
}
.pf-v5-c-data-list__item.pf-m-clickable:hover {
  box-shadow: var(--pf-v5-c-data-list__item--m-clickable--hover--BoxShadow);
}
.pf-v5-c-data-list__item.pf-m-clickable:focus {
  box-shadow: var(--pf-v5-c-data-list__item--m-clickable--focus--BoxShadow);
}
.pf-v5-c-data-list__item.pf-m-clickable:active {
  box-shadow: var(--pf-v5-c-data-list__item--m-clickable--active--BoxShadow);
}
.pf-v5-c-data-list__item.pf-m-selected {
  --pf-v5-c-data-list__item--before--BackgroundColor: var(--pf-v5-c-data-list__item--m-selected--before--BackgroundColor);
  position: relative;
  z-index: var(--pf-v5-c-data-list__item--m-selected--ZIndex);
  box-shadow: var(--pf-v5-c-data-list__item--m-selected--BoxShadow);
}
.pf-v5-c-data-list__item.pf-m-ghost-row::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  background-color: var(--pf-v5-c-data-list__item--m-ghost-row--after--BackgroundColor);
  opacity: var(--pf-v5-c-data-list__item--m-ghost-row--after--Opacity);
}
.pf-v5-c-data-list__item.pf-m-expanded {
  --pf-v5-c-data-list__toggle-icon--Rotate: var(--pf-v5-c-data-list__item--m-expanded__toggle-icon--Rotate);
}

.pf-v5-c-data-list__item-row {
  display: flex;
  flex-wrap: nowrap;
  padding-inline-start: var(--pf-v5-c-data-list__item-row--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-data-list__item-row--PaddingRight);
}

.pf-v5-c-data-list__item-control {
  display: flex;
  flex-wrap: nowrap;
  padding-block-start: var(--pf-v5-c-data-list__item-control--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__item-control--PaddingBottom);
  margin-inline-end: var(--pf-v5-c-data-list__item-control--MarginRight);
}
.pf-v5-c-data-list__item-control > *:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-data-list__item-control--not-last-child--MarginRight);
}

.pf-v5-c-data-list__check {
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: var(--pf-v5-c-data-list__check--Height);
  margin-block-start: var(--pf-v5-c-data-list__check--MarginTop);
}
.pf-v5-c-data-list__check > input {
  cursor: pointer;
}

.pf-v5-c-data-list__item-draggable-button {
  display: flex;
  flex-direction: column;
  padding-block-start: var(--pf-v5-c-data-list__item-draggable-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__item-draggable-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-data-list__item-draggable-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-data-list__item-draggable-button--PaddingRight);
  margin-block-start: var(--pf-v5-c-data-list__item-draggable-button--MarginTop);
  margin-block-end: var(--pf-v5-c-data-list__item-draggable-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-data-list__item-draggable-button--MarginLeft);
  background-color: var(--pf-v5-c-data-list__item-draggable-button--BackgroundColor);
  border: 0;
}
.pf-v5-c-data-list__item-draggable-button:hover {
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-c-data-list__item-draggable-button--hover__draggable-icon--Color);
  cursor: grab;
}
.pf-v5-c-data-list__item-draggable-button:focus {
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-c-data-list__item-draggable-button--focus__draggable-icon--Color);
}
.pf-v5-c-data-list__item-draggable-button:active {
  cursor: grabbing;
}
.pf-v5-c-data-list__item-draggable-button.pf-m-disabled {
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-c-data-list__item-draggable-button--m-disabled__draggable-icon--Color);
  pointer-events: none;
}
.pf-v5-c-data-list__item-draggable-button .pf-v5-c-data-list__item-draggable-icon {
  color: var(--pf-v5-c-data-list__item-draggable-button-icon--Color);
}

.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-data-list__item-action--Display);
  align-content: flex-start;
  align-items: flex-start;
  padding-block-start: var(--pf-v5-c-data-list__item-action--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__item-action--PaddingBottom);
  margin-inline-start: var(--pf-v5-c-data-list__item-action--MarginLeft);
}
.pf-v5-c-data-list__item-action > *:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-data-list__item-action--not-last-child--MarginRight);
}
.pf-v5-c-data-list__item-action .pf-v5-c-data-list__action {
  margin-block-start: var(--pf-v5-c-data-list__action--MarginTop);
  margin-block-end: var(--pf-v5-c-data-list__item-action__action--MarginBottom);
}

.pf-v5-c-data-list__toggle {
  margin-block-start: var(--pf-v5-c-data-list__toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-data-list__toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-data-list__toggle--MarginLeft);
}

.pf-v5-c-data-list__toggle-icon {
  height: var(--pf-v5-c-data-list__toggle-icon--Height);
  pointer-events: none;
  transition: var(--pf-v5-c-data-list__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-data-list__toggle-icon--Rotate));
}
:where(.pf-v5-m-dir-rtl, [dir=rtl]) .pf-v5-c-data-list__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-data-list__item-content {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
}

.pf-v5-c-data-list__cell {
  flex: 1;
  grid-column: 1/-1;
  padding-block-start: var(--pf-v5-c-data-list__cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__cell--PaddingBottom);
}
.pf-v5-c-data-list__cell + .pf-v5-c-data-list__cell {
  flex: 1 0 100%;
  order: 1;
  padding-block-start: var(--pf-v5-c-data-list__cell--cell--PaddingTop);
}
.pf-v5-c-data-list__cell.pf-m-icon {
  flex-grow: 0;
  grid-column: 1/2;
  margin-inline-end: var(--pf-v5-c-data-list__cell--m-icon--MarginRight);
}
.pf-v5-c-data-list__cell.pf-m-icon + .pf-v5-c-data-list__cell {
  grid-column: 2/3;
  padding-block-start: var(--pf-v5-c-data-list__cell--m-icon--cell--PaddingTop);
}
.pf-v5-c-data-list__cell.pf-m-align-right {
  margin-inline-start: 0;
}

.pf-v5-c-data-list__text {
  display: inline-block;
}

.pf-v5-c-data-list__text,
.pf-v5-c-data-list__cell {
  min-width: var(--pf-v5-c-data-list--cell--MinWidth);
  max-width: 100%;
  overflow: var(--pf-v5-c-data-list--cell--Overflow);
  text-overflow: var(--pf-v5-c-data-list--cell--TextOverflow);
  word-break: var(--pf-v5-c-data-list--cell--WordBreak);
  white-space: var(--pf-v5-c-data-list--cell--WhiteSpace);
}

.pf-v5-c-data-list__expandable-content {
  max-height: var(--pf-v5-c-data-list__expandable-content--MaxHeight);
  overflow-y: auto;
}
.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body {
  padding-block-start: var(--pf-v5-c-data-list__expandable-content-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__expandable-content-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-data-list__expandable-content-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-data-list__expandable-content-body--PaddingRight);
}
.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body > .pf-v5-c-data-list {
  margin-inline-start: var(--pf-v5-c-data-list--MarginLeft);
}
.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body .pf-v5-c-data-list__item:last-child {
  border-block-end: 0;
}
.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body .pf-v5-c-data-list__item-row {
  --pf-v5-c-data-list__item-row--PaddingLeft: 0;
}
.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body .pf-v5-c-data-list__expandable-content-body {
  --pf-v5-c-data-list__expandable-content-body--PaddingLeft: 0;
}
.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body.pf-m-no-padding {
  padding: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-data-list {
  --pf-v5-c-data-list--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list--sm--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list__item--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list__item--sm--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
}