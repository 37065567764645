.pf-v5-l-grid {
  --pf-v5-l-grid--m-gutter--GridGap: var(--pf-v5-global--gutter);
  --pf-v5-l-grid__item--GridColumnStart: auto;
  --pf-v5-l-grid__item--GridColumnEnd: span 12;
  --pf-v5-l-grid--item--Order: 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
}
.pf-v5-l-grid > *,
.pf-v5-l-grid .pf-v5-l-grid__item {
  grid-column-start: var(--pf-v5-l-grid__item--GridColumnStart);
  grid-column-end: var(--pf-v5-l-grid__item--GridColumnEnd);
  min-width: 0;
  min-height: 0;
  order: var(--pf-v5-l-grid--item--Order);
}
@media (min-width: 576px) {
  .pf-v5-l-grid > *,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order));
  }
}
@media (min-width: 768px) {
  .pf-v5-l-grid > *,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order)));
  }
}
@media (min-width: 992px) {
  .pf-v5-l-grid > *,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-lg, var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-l-grid > *,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-xl, var(--pf-v5-l-grid--item--Order-on-lg, var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-l-grid > *,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-2xl, var(--pf-v5-l-grid--item--Order-on-xl, var(--pf-v5-l-grid--item--Order-on-lg, var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order))))));
  }
}
.pf-v5-l-grid.pf-m-all-1-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 1;
}
.pf-v5-l-grid.pf-m-all-2-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 2;
}
.pf-v5-l-grid.pf-m-all-3-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 3;
}
.pf-v5-l-grid.pf-m-all-4-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 4;
}
.pf-v5-l-grid.pf-m-all-5-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 5;
}
.pf-v5-l-grid.pf-m-all-6-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 6;
}
.pf-v5-l-grid.pf-m-all-7-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 7;
}
.pf-v5-l-grid.pf-m-all-8-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 8;
}
.pf-v5-l-grid.pf-m-all-9-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 9;
}
.pf-v5-l-grid.pf-m-all-10-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 10;
}
.pf-v5-l-grid.pf-m-all-11-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 11;
}
.pf-v5-l-grid.pf-m-all-12-col > * {
  --pf-v5-l-grid__item--GridColumnEnd: span 12;
}
@media screen and (min-width: 576px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid.pf-m-all-2-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid.pf-m-all-3-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid.pf-m-all-4-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid.pf-m-all-5-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid.pf-m-all-6-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid.pf-m-all-7-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid.pf-m-all-8-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid.pf-m-all-9-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid.pf-m-all-10-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid.pf-m-all-11-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid.pf-m-all-12-col-on-sm > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}
@media screen and (min-width: 768px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid.pf-m-all-2-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid.pf-m-all-3-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid.pf-m-all-4-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid.pf-m-all-5-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid.pf-m-all-6-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid.pf-m-all-7-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid.pf-m-all-8-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid.pf-m-all-9-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid.pf-m-all-10-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid.pf-m-all-11-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid.pf-m-all-12-col-on-md > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}
@media screen and (min-width: 992px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid.pf-m-all-2-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid.pf-m-all-3-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid.pf-m-all-4-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid.pf-m-all-5-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid.pf-m-all-6-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid.pf-m-all-7-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid.pf-m-all-8-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid.pf-m-all-9-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid.pf-m-all-10-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid.pf-m-all-11-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid.pf-m-all-12-col-on-lg > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}
@media screen and (min-width: 1200px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid.pf-m-all-2-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid.pf-m-all-3-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid.pf-m-all-4-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid.pf-m-all-5-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid.pf-m-all-6-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid.pf-m-all-7-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid.pf-m-all-8-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid.pf-m-all-9-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid.pf-m-all-10-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid.pf-m-all-11-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid.pf-m-all-12-col-on-xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}
@media screen and (min-width: 1450px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid.pf-m-all-2-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid.pf-m-all-3-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid.pf-m-all-4-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid.pf-m-all-5-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid.pf-m-all-6-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid.pf-m-all-7-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid.pf-m-all-8-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid.pf-m-all-9-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid.pf-m-all-10-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid.pf-m-all-11-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid.pf-m-all-12-col-on-2xl > * {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}
.pf-v5-l-grid > .pf-m-1-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 1;
}
.pf-v5-l-grid > .pf-m-2-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 2;
}
.pf-v5-l-grid > .pf-m-3-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 3;
}
.pf-v5-l-grid > .pf-m-4-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 4;
}
.pf-v5-l-grid > .pf-m-5-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 5;
}
.pf-v5-l-grid > .pf-m-6-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 6;
}
.pf-v5-l-grid > .pf-m-7-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 7;
}
.pf-v5-l-grid > .pf-m-8-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 8;
}
.pf-v5-l-grid > .pf-m-9-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 9;
}
.pf-v5-l-grid > .pf-m-10-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 10;
}
.pf-v5-l-grid > .pf-m-11-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 11;
}
.pf-v5-l-grid > .pf-m-12-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 12;
}
.pf-v5-l-grid > .pf-m-offset-1-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
}
.pf-v5-l-grid > .pf-m-offset-2-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
}
.pf-v5-l-grid > .pf-m-offset-3-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
}
.pf-v5-l-grid > .pf-m-offset-4-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
}
.pf-v5-l-grid > .pf-m-offset-5-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
}
.pf-v5-l-grid > .pf-m-offset-6-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
}
.pf-v5-l-grid > .pf-m-offset-7-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
}
.pf-v5-l-grid > .pf-m-offset-8-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
}
.pf-v5-l-grid > .pf-m-offset-9-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
}
.pf-v5-l-grid > .pf-m-offset-10-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
}
.pf-v5-l-grid > .pf-m-offset-11-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
}
.pf-v5-l-grid > .pf-m-offset-12-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
}
.pf-v5-l-grid > .pf-m-1-row {
  grid-row: span 1;
}
.pf-v5-l-grid > .pf-m-2-row {
  grid-row: span 2;
}
.pf-v5-l-grid > .pf-m-3-row {
  grid-row: span 3;
}
.pf-v5-l-grid > .pf-m-4-row {
  grid-row: span 4;
}
.pf-v5-l-grid > .pf-m-5-row {
  grid-row: span 5;
}
.pf-v5-l-grid > .pf-m-6-row {
  grid-row: span 6;
}
.pf-v5-l-grid > .pf-m-7-row {
  grid-row: span 7;
}
.pf-v5-l-grid > .pf-m-8-row {
  grid-row: span 8;
}
.pf-v5-l-grid > .pf-m-9-row {
  grid-row: span 9;
}
.pf-v5-l-grid > .pf-m-10-row {
  grid-row: span 10;
}
.pf-v5-l-grid > .pf-m-11-row {
  grid-row: span 11;
}
.pf-v5-l-grid > .pf-m-12-row {
  grid-row: span 12;
}
@media screen and (min-width: 576px) {
  .pf-v5-l-grid > .pf-m-1-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
  .pf-v5-l-grid > .pf-m-offset-1-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-2-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-3-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-4-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-5-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-6-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-7-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-8-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-9-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-10-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-11-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-12-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }
  .pf-v5-l-grid > .pf-m-1-row-on-sm {
    grid-row: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-row-on-sm {
    grid-row: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-row-on-sm {
    grid-row: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-row-on-sm {
    grid-row: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-row-on-sm {
    grid-row: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-row-on-sm {
    grid-row: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-row-on-sm {
    grid-row: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-row-on-sm {
    grid-row: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-row-on-sm {
    grid-row: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-row-on-sm {
    grid-row: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-row-on-sm {
    grid-row: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-row-on-sm {
    grid-row: span 12;
  }
}
@media screen and (min-width: 768px) {
  .pf-v5-l-grid > .pf-m-1-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
  .pf-v5-l-grid > .pf-m-offset-1-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-2-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-3-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-4-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-5-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-6-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-7-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-8-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-9-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-10-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-11-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-12-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }
  .pf-v5-l-grid > .pf-m-1-row-on-md {
    grid-row: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-row-on-md {
    grid-row: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-row-on-md {
    grid-row: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-row-on-md {
    grid-row: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-row-on-md {
    grid-row: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-row-on-md {
    grid-row: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-row-on-md {
    grid-row: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-row-on-md {
    grid-row: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-row-on-md {
    grid-row: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-row-on-md {
    grid-row: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-row-on-md {
    grid-row: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-row-on-md {
    grid-row: span 12;
  }
}
@media screen and (min-width: 992px) {
  .pf-v5-l-grid > .pf-m-1-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
  .pf-v5-l-grid > .pf-m-offset-1-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-2-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-3-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-4-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-5-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-6-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-7-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-8-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-9-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-10-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-11-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-12-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }
  .pf-v5-l-grid > .pf-m-1-row-on-lg {
    grid-row: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-row-on-lg {
    grid-row: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-row-on-lg {
    grid-row: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-row-on-lg {
    grid-row: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-row-on-lg {
    grid-row: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-row-on-lg {
    grid-row: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-row-on-lg {
    grid-row: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-row-on-lg {
    grid-row: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-row-on-lg {
    grid-row: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-row-on-lg {
    grid-row: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-row-on-lg {
    grid-row: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-row-on-lg {
    grid-row: span 12;
  }
}
@media screen and (min-width: 1200px) {
  .pf-v5-l-grid > .pf-m-1-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
  .pf-v5-l-grid > .pf-m-offset-1-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-2-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-3-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-4-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-5-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-6-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-7-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-8-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-9-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-10-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-11-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-12-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }
  .pf-v5-l-grid > .pf-m-1-row-on-xl {
    grid-row: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-row-on-xl {
    grid-row: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-row-on-xl {
    grid-row: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-row-on-xl {
    grid-row: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-row-on-xl {
    grid-row: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-row-on-xl {
    grid-row: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-row-on-xl {
    grid-row: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-row-on-xl {
    grid-row: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-row-on-xl {
    grid-row: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-row-on-xl {
    grid-row: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-row-on-xl {
    grid-row: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-row-on-xl {
    grid-row: span 12;
  }
}
@media screen and (min-width: 1450px) {
  .pf-v5-l-grid > .pf-m-1-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
  .pf-v5-l-grid > .pf-m-offset-1-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-2-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-3-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-4-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-5-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-6-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-7-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-8-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-9-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-10-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-11-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }
  .pf-v5-l-grid > .pf-m-offset-12-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }
  .pf-v5-l-grid > .pf-m-1-row-on-2xl {
    grid-row: span 1;
  }
  .pf-v5-l-grid > .pf-m-2-row-on-2xl {
    grid-row: span 2;
  }
  .pf-v5-l-grid > .pf-m-3-row-on-2xl {
    grid-row: span 3;
  }
  .pf-v5-l-grid > .pf-m-4-row-on-2xl {
    grid-row: span 4;
  }
  .pf-v5-l-grid > .pf-m-5-row-on-2xl {
    grid-row: span 5;
  }
  .pf-v5-l-grid > .pf-m-6-row-on-2xl {
    grid-row: span 6;
  }
  .pf-v5-l-grid > .pf-m-7-row-on-2xl {
    grid-row: span 7;
  }
  .pf-v5-l-grid > .pf-m-8-row-on-2xl {
    grid-row: span 8;
  }
  .pf-v5-l-grid > .pf-m-9-row-on-2xl {
    grid-row: span 9;
  }
  .pf-v5-l-grid > .pf-m-10-row-on-2xl {
    grid-row: span 10;
  }
  .pf-v5-l-grid > .pf-m-11-row-on-2xl {
    grid-row: span 11;
  }
  .pf-v5-l-grid > .pf-m-12-row-on-2xl {
    grid-row: span 12;
  }
}
.pf-v5-l-grid.pf-m-gutter {
  grid-gap: var(--pf-v5-l-grid--m-gutter--GridGap);
}