.pf-v5-l-gallery {
  --pf-v5-l-gallery--m-gutter--GridGap: var(--pf-v5-global--gutter);
  --pf-v5-l-gallery--GridTemplateColumns--min: 250px;
  --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min);
  --pf-v5-l-gallery--GridTemplateColumns--max: 1fr;
  --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max);
  --pf-v5-l-gallery--GridTemplateColumns: repeat(auto-fill, minmax(var(--pf-v5-l-gallery--GridTemplateColumns--minmax--min), var(--pf-v5-l-gallery--GridTemplateColumns--minmax--max)));
  --pf-v5-l-gallery--GridTemplateRows: auto;
  display: grid;
  grid-template-rows: var(--pf-v5-l-gallery--GridTemplateRows);
  grid-template-columns: var(--pf-v5-l-gallery--GridTemplateColumns);
  --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min);
  --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max);
}
.pf-v5-l-gallery.pf-m-gutter {
  grid-gap: var(--pf-v5-l-gallery--m-gutter--GridGap);
}
@media (min-width: 576px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min));
  }
}
@media (min-width: 768px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min)));
  }
}
@media (min-width: 992px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-2xl, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min))))));
  }
}
@media (min-width: 576px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max));
  }
}
@media (min-width: 768px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max)));
  }
}
@media (min-width: 992px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max))));
  }
}
@media (min-width: 1200px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max)))));
  }
}
@media (min-width: 1450px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-2xl, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max))))));
  }
}