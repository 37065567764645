:where(.pf-v5-theme-dark) .pf-v5-chart svg g[clip-path] {
  mix-blend-mode: normal;
}
:where(.pf-v5-theme-dark) {
  --pf-v5-chart-color-blue-100: #add6ff;
  --pf-v5-chart-color-blue-200: #85c2ff;
  --pf-v5-chart-color-blue-300: #47a3ff;
  --pf-v5-chart-color-blue-400: #0a85ff;
  --pf-v5-chart-color-blue-500: #06c;
  --pf-v5-chart-color-green-100: #d6eed3;
  --pf-v5-chart-color-green-200: #aedca7;
  --pf-v5-chart-color-green-300: #85cb7c;
  --pf-v5-chart-color-green-400: #5cb950;
  --pf-v5-chart-color-green-500: #3e8635;
  --pf-v5-chart-color-cyan-100: #b9feff;
  --pf-v5-chart-color-cyan-200: #86fdff;
  --pf-v5-chart-color-cyan-300: #00b5b8;
  --pf-v5-chart-color-cyan-400: #008c8f;
  --pf-v5-chart-color-cyan-500: #005f60;
  --pf-v5-chart-color-purple-100: #cec8e4;
  --pf-v5-chart-color-purple-200: #a99fd1;
  --pf-v5-chart-color-purple-300: #9183c3;
  --pf-v5-chart-color-purple-400: #7968b6;
  --pf-v5-chart-color-purple-500: #6753ac;
  --pf-v5-chart-color-red-100: #ffadad;
  --pf-v5-chart-color-red-200: #ff7070;
  --pf-v5-chart-color-red-300: #ff4747;
  --pf-v5-chart-color-red-400: #ff0a0a;
  --pf-v5-chart-color-red-500: #a30000;
  --pf-v5-chart-global--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-chart-global--BorderColor--accent: var(--pf-v5-global--BorderColor--400);
  --pf-v5-chart-global--label--Fill: var(--pf-v5-global--Color--100);
  --pf-v5-chart-global--title--Fill: var(--pf-v5-global--Color--100);
  --pf-v5-chart-global--subtitle--Fill: var(--pf-v5-global--Color--200);
  --pf-v5-chart-global--tooltip--Fill: var(--pf-v5-global--Color--100);
  --pf-v5-chart-global--tooltip--bg--Fill: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-chart-global--tooltip--BorderWidth: 1;
  --pf-v5-chart-global--tooltip--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-chart-axis--axis--stroke--Color: var(--pf-v5-chart-global--BorderColor);
  --pf-v5-chart-axis--grid--stroke--Color: var(--pf-v5-chart-global--BorderColor);
  --pf-v5-chart-axis--tick--stroke--Color: var(--pf-v5-chart-global--BorderColor);
  --pf-v5-chart-axis--axis--tick--stroke--Color: var(--pf-v5-chart-global--BorderColor);
  --pf-v5-chart-axis--grid--Fill: var(--pf-v5-chart-global--BorderColor);
  --pf-v5-chart-axis--tick-label--Fill: var(--pf-v5-chart-global--label--Fill);
  --pf-v5-chart-bullet--label--title--Fill: var(--pf-v5-chart-global--title--Fill);
  --pf-v5-chart-bullet--label--grouptitle--Fill: var(--pf-v5-chart-bullet--label--title--Fill);
  --pf-v5-chart-bullet--label--subtitle--Fill: var(--pf-v5-chart-global--subtitle--Fill);
  --pf-v5-chart-bullet--negative-measure--ColorScale--100: var(--pf-v5-global--palette--red-400);
  --pf-v5-chart-bullet--negative-measure--ColorScale--200: var(--pf-v5-global--palette--red-300);
  --pf-v5-chart-bullet--negative-measure--ColorScale--300: var(--pf-v5-global--palette--red-200);
  --pf-v5-chart-bullet--negative-measure--ColorScale--400: var(--pf-v5-global--palette--red-100);
  --pf-v5-chart-bullet--negative-measure--ColorScale--500: var(--pf-v5-global--palette--red-9999);
  --pf-v5-chart-bullet--qualitative-range--ColorScale--100: var(--pf-v5-global--palette--black-600);
  --pf-v5-chart-bullet--qualitative-range--ColorScale--200: var(--pf-v5-global--palette--black-500);
  --pf-v5-chart-bullet--qualitative-range--ColorScale--300: var(--pf-v5-global--palette--black-400);
  --pf-v5-chart-bullet--qualitative-range--ColorScale--400: var(--pf-v5-global--palette--black-300);
  --pf-v5-chart-bullet--qualitative-range--ColorScale--500: var(--pf-v5-global--palette--black-200);
  --pf-v5-chart-container--cursor--line--Fill: var(--pf-v5-chart-global--BorderColor--accent);
  --pf-v5-chart-donut--label--title--Fill: var(--pf-v5-chart-global--title--Fill);
  --pf-v5-chart-donut--label--subtitle--Fill: var(--pf-v5-chart-global--subtitle--Fill);
  --pf-v5-chart-line--data--stroke--Color: var(--pf-v5-chart-global--BorderColor);
  --pf-v5-chart-tooltip--Fill: var(--pf-v5-chart-global--tooltip--Fill);
  --pf-v5-chart-voronoi--labels--Fill: var(--pf-v5-chart-global--tooltip--Fill);
  --pf-v5-chart-tooltip--flyoutStyle--Fill: var(--pf-v5-chart-global--tooltip--bg--Fill);
  --pf-v5-chart-voronoi--flyout--stroke--Fill: var(--pf-v5-chart-global--tooltip--bg--Fill);
  --pf-v5-chart-tooltip--flyoutStyle--stroke--Width: var(--pf-v5-chart-global--tooltip--BorderWidth);
  --pf-v5-chart-tooltip--flyoutStyle--stroke--Color: var(--pf-v5-chart-global--tooltip--BorderColor);
  --pf-v5-chart-voronoi--flyout--stroke--Width: var(--pf-v5-chart-global--tooltip--BorderWidth);
  --pf-v5-chart-voronoi--flyout--stroke--Color: var(--pf-v5-chart-global--tooltip--BorderColor);
}