.pf-v5-c-simple-list {
  --pf-v5-c-simple-list__item-link--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-simple-list__item-link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__item-link--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-simple-list__item-link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--m-current--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-simple-list__item-link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--m-current--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--focus--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--active--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-simple-list__title--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__title--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-simple-list__title--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__title--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-simple-list__title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-simple-list__title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-simple-list__section--section--MarginTop: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-simple-list__item-link {
  display: block;
  width: 100%;
  padding-block-start: var(--pf-v5-c-simple-list__item-link--PaddingTop);
  padding-block-end: var(--pf-v5-c-simple-list__item-link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-simple-list__item-link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-simple-list__item-link--PaddingRight);
  font-size: var(--pf-v5-c-simple-list__item-link--FontSize);
  font-weight: var(--pf-v5-c-simple-list__item-link--FontWeight);
  color: var(--pf-v5-c-simple-list__item-link--Color);
  text-align: start;
  background-color: var(--pf-v5-c-simple-list__item-link--BackgroundColor);
  border: none;
}
.pf-v5-c-simple-list__item-link:hover {
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--hover--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--hover--Color);
  text-decoration: none;
}
.pf-v5-c-simple-list__item-link:focus {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--focus--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--focus--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--focus--Color);
}
.pf-v5-c-simple-list__item-link:active {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--active--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--active--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--active--Color);
}
.pf-v5-c-simple-list__item-link.pf-m-current {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--m-current--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--m-current--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--m-current--Color);
}

.pf-v5-c-simple-list__title {
  padding-block-start: var(--pf-v5-c-simple-list__title--PaddingTop);
  padding-block-end: var(--pf-v5-c-simple-list__title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-simple-list__title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-simple-list__title--PaddingRight);
  font-size: var(--pf-v5-c-simple-list__title--FontSize);
  font-weight: var(--pf-v5-c-simple-list__title--FontWeight);
  color: var(--pf-v5-c-simple-list__title--Color);
}

.pf-v5-c-simple-list__section + .pf-v5-c-simple-list__section {
  margin-block-start: var(--pf-v5-c-simple-list__section--section--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-simple-list {
  --pf-v5-c-simple-list__item-link--m-current--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-simple-list__item-link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}