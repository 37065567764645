.pf-v5-l-stack {
  --pf-v5-l-stack--m-gutter--Gap: var(--pf-v5-global--gutter);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pf-v5-l-stack__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-l-stack.pf-m-gutter {
  gap: var(--pf-v5-l-stack--m-gutter--Gap);
}