.pf-v5-c-skip-to-content {
  --pf-v5-c-skip-to-content--Top: var(--pf-v5-global--spacer--md);
  --pf-v5-c-skip-to-content--ZIndex: var(--pf-v5-global--ZIndex--2xl);
  --pf-v5-c-skip-to-content--focus--Left: var(--pf-v5-global--spacer--md);
  position: absolute;
  inset-block-start: var(--pf-v5-c-skip-to-content--Top);
  inset-inline-start: -300%;
  z-index: var(--pf-v5-c-skip-to-content--ZIndex);
}
.pf-v5-c-skip-to-content:focus-within {
  inset-inline-start: var(--pf-v5-c-skip-to-content--focus--Left);
}